import { state } from './enum/state';
import { PropertyValue } from './property-value';
import { objectType } from './enum/object-type';

export class Printable {
    public uuid: string;
    public internal_code: string;
    public name: string;
    public description: string;
    public codiceGara: string;
    public properties: PropertyValue[];
    public objectType: objectType;
    public tags: string;
    public version: number;

    public categoria_property: string;
    public category_uuid: string;
    public tipology_uuid: string;
    public propertyName?: string;
    public propertyValue?: string | number;
    public state: state;
    public closed: boolean;
    public active: boolean;

    constructor() {}
}
