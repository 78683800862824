<div class="p-p-3">
  <div class="custom-filters">
    <div class="row w-full">
      <div class="col-sm-6">
        <span class="p-input-icon-right w-full">
          <input
            pInputText
            type="text"
            placeholder="cod. art. interno..."
            class="form-control"
            [(ngModel)]="service.search.like.internal_code"
          />
          <i
            class="fa fa-close icon"
            *ngIf="service.search.like.internal_code"
            (click)="clearFilter($event, 'like', 'internal_code')"
          ></i>
        </span>
      </div>

      <div class="col-sm-6">
        <span class="p-input-icon-right w-full">
          <input
            pInputText
            type="text"
            placeholder="cod. articolo..."
            class="form-control"
            [(ngModel)]="service.search.like.name"
          />
          <i
            class="fa fa-close icon"
            *ngIf="service.search.like.name"
            (click)="clearFilter($event, 'like', 'name')"
          ></i>
        </span>
      </div>

      <div class="col-sm-6 p-pt-3">
        <span class="p-input-icon-right w-full">
          <input
            pInputText
            type="text"
            placeholder="descrizione..."
            class="form-control"
            [(ngModel)]="service.search.like.description"
          />
          <i
            class="fa fa-close icon"
            *ngIf="service.search.like.description"
            (click)="clearFilter($event, 'like', 'description')"
          ></i>
        </span>
      </div>

      <div class="col-sm-6 p-pt-3">
        <div class="form-group cleafix">
          <p-autoComplete
            [(ngModel)]="selectedCategoryFilter"
            [suggestions]="categoryOptions"
            (completeMethod)="filterCategory($event)"
            field="name"
            placeholder="categoria..."
            (onSelect)="fillCategory()"
            (onClear)="clearCategory()"
            [style]="{ width: '100%' }"
            [dropdown]="true"
          ></p-autoComplete>
        </div>
      </div>

      <div class="col-sm-12 p-pt-3 filter-btns">
        <button type="button" class="btn btn-success" (click)="reset(table)">
          Annulla
        </button>
        <button type="button" class="btn btn-success" (click)="reload(table)">
          Cerca
        </button>
      </div>
    </div>
  </div>

  <div class="custom-select-btns">
    <button
      type="button"
      class="btn btn-success"
      [disabled]="selected.length === 0"
      (click)="unselectAll()"
    >
      Deseleziona
    </button>
    <button
      type="button"
      class="btn btn-success"
      [disabled]="selected.length === 0"
      (click)="select()"
    >
      Seleziona
    </button>
  </div>

  <p-table
    [value]="model"
    [(selection)]="selected"
    [lazy]="true"
    [loading]="loading"
    (onLazyLoad)="lazyLoad($event)"
    [paginator]="true"
    [responsive]="true"
    [rows]="service.search.pageSize"
    [totalRecords]="service.listSize"
    [resizableColumns]="true"
    [loading]="selectLoading"
    #table
  >
    <ng-template pTemplate="header" let-columns class="search-filter">
      <tr class="sort-header">
        <th>
          <p-checkbox
            [(ngModel)]="maxSelected"
            (click)="onManualSelectAll()"
            [binary]="true"
          ></p-checkbox>
        </th>
        <th [style]="{ width: '30px !important' }"></th>
        <th pResizableColumn [pSortableColumn]="'internal_code'">
          Cod. art. Interno
          <p-sortIcon [field]="'internal_code'"></p-sortIcon>
        </th>
        <th pResizableColumn [pSortableColumn]="'name'">
          Cod. Articolo
          <p-sortIcon [field]="'name'"></p-sortIcon>
        </th>
        <th pResizableColumn [pSortableColumn]="'description'">
          Descrizione
          <p-sortIcon [field]="'description'"></p-sortIcon>
        </th>
        <th pResizableColumn [pSortableColumn]="'categoria_property'">
          Categoria
          <p-sortIcon [field]="'categoria_property'"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="colspan">Nessun risultato.</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr>
        <td [ngClass]="isSelected(rowData) ? 'bg-light-gray' : ''">
          <p-checkbox
            [(ngModel)]="rowData['selected']"
            (click)="onManualSelect(rowData)"
            [binary]="true"
          ></p-checkbox>
        </td>
        <td
          [style]="{ width: '30px !important' }"
          [ngClass]="isSelected(rowData) ? 'bg-light-gray' : ''"
        >
          {{ rowIndex + 1 }}
        </td>
        <td class="ui-resizable-column" [ngClass]="isSelected(rowData) ? 'bg-light-gray' : ''">
          {{ rowData.internal_code }}
        </td>
        <td class="ui-resizable-column" [ngClass]="isSelected(rowData) ? 'bg-light-gray' : ''">
          {{ rowData.name }}
        </td>
        <td class="ui-resizable-column" [ngClass]="isSelected(rowData) ? 'bg-light-gray' : ''">
          {{ rowData.description }}
        </td>
        <td class="ui-resizable-column" [ngClass]="isSelected(rowData) ? 'bg-light-gray' : ''">
          {{ mapCategory[rowData.uuid] }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
