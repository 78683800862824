import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PrintableService } from 'src/app/service/printable.service';
import { PrintableListComponent } from 'src/app/components/printable/printable-list.component';
import { ArticleService } from 'src/app/service/article.service';
import { CategoryService } from 'src/app/service/category.service';
import { TipologyService } from 'src/app/service/tipology.service';
import { ListableService } from 'src/app/service/listable.service';
import { CONTAINERS_API_PATH, MAX_PAGE_SIZE } from 'src/app/constants/constants';
import { Tipology } from 'src/app/model/tipology';
import { Container } from 'src/app/model/container';
import { Printable } from 'src/app/model/printable';
import { MessageService, Table } from 'primeng';
import { ConfigurationService } from 'src/app/service/configuration.service';
import { Search } from 'src/app/common/search';

@Component({
    selector: 'app-select-articoli',
    templateUrl: './select-articoli.component.html',
})
export class SelectArticoliComponent extends PrintableListComponent {
    @Input() preSelected: any[] = [];

    @Input() selectedContainer: Container;
    @Input() selectedTipology: Tipology;
    @Input() selectedProperties: string[] = [];

    @Output() onSelect = new EventEmitter();

    @ViewChild('table') table: Table;

    selected: any[] = [];

    categoryOptions: { name: string }[] = [];
    selectedCategoryFilter: { name: string } = null;
    mapCategory: { [k: string]: string } = {};

    maxSelected: boolean = false;

    selectLoading: boolean = false;

    constructor(
        protected router: Router,
        protected printableService: PrintableService,
        protected route: ActivatedRoute,
        protected articleService: ArticleService,
        protected categoryService: CategoryService,
        protected tipologyService: TipologyService,
        protected listableService: ListableService,
        private cd: ChangeDetectorRef,
        private configurationService: ConfigurationService,
        private messagesService: MessageService
    ) {
        super(
            router,
            route,
            'articles',
            articleService,
            categoryService,
            tipologyService,
            listableService
        );

        this.service.buildSearch();
        this.service.search = new Search<Printable>(Printable);
    }

    ngOnInit(): void {
        this.selected = [...this.preSelected];
        this.cd.detectChanges();
        super.ngOnInit();
    }

    preLoaddata(): void {
        this.configurationService.getValue(CONTAINERS_API_PATH).subscribe({
            next: (url) => {
                this.service.url = `${url}/${this.selectedContainer.codiceGara}/printables`;
                this.service.search.obj.objectType = null;
            },
        });

        if (this.selectedContainer) {
            this.service.search.obj.codiceGara = this.selectedContainer.codiceGara;
        }

        if (this.selectedTipology) {
            this.service.search.obj.tipology_uuid = this.selectedTipology.uuid;
        }

        this.service.search.orderBy = 'name ASC';

        super.preLoaddata();
    }

    postList(): void {
        for (const item of this.model) {
            const find = this.selected.find((inF) => inF.uuid === item.uuid);

            if (find) {
                item['selected'] = true;
            } else {
                item['selected'] = false;
            }

            const properties = [...item.properties];
            const filteredProperties = properties.filter((inP) => inP.name === 'categoria');
            if (filteredProperties.length > 0) {
                const arrToString = [];
                for (const prop of filteredProperties) {
                    if (prop.value) {
                        if (prop.value.length > 0) {
                            for (const value of prop.value) {
                                arrToString.push(value);
                            }
                        }
                    }
                }

                this.mapCategory[item.uuid] = arrToString.toString();
            }
        }

        if (
            this.selected.length === this.service.listSize ||
            this.selected.length === MAX_PAGE_SIZE
        ) {
            this.maxSelected = true;
        } else {
            this.maxSelected = false;
        }

        super.postList();
    }

    reload(table: Table): void {
        if (!this.service.search.like.categoria_property) {
            this.selectedCategoryFilter = null;
        }

        super.reload(table);
    }

    reset(table: Table): void {
        this.service.search.obj.objectType = null;
        this.selectedCategoryFilter = null;

        super.reset(table);
    }

    unselectAll(): void {
        this.selected = [];
        this.select();
    }

    select(): void {
        this.checkLength();

        this.selected = this.selected.filter(
            (thing, index, self) => self.findIndex((t) => t.uuid === thing.uuid) === index
        );
        this.onSelect.emit(this.selected);
    }

    checkLength(): void {
        if (this.selected.length > MAX_PAGE_SIZE) {
            this.selected.splice(0, MAX_PAGE_SIZE);
        }
    }

    filterCategory(event = null): void {
        this.listableService.getListablesCategories(event.query).subscribe(
            (list) => {
                const localOptions: { name: string }[] = [];
                for (const item of list) {
                    localOptions.push({
                        name: item,
                    });
                }

                this.categoryOptions = [...localOptions];
            },
            () => {
                console.log('Errore nel filraggio dei piani');
            }
        );
    }
    fillCategory(): void {
        this.service.search.like.categoria_property = this.selectedCategoryFilter.name;
    }

    isSelected(printable: Printable): boolean {
        const found = this.selected.find((inSel) => inSel.uuid === printable.uuid);

        if (found) {
            return true;
        }

        return false;
    }

    clearFilter(event: any, filterPrefix: string, filter: string): void {
        event.stopPropagation();

        this.service.search[filterPrefix][filter] = null;
        this.reload(this.table);
    }

    onManualSelect(rowData: any): void {
        const outerIndex = this.model.findIndex((inF) => inF.uuid === rowData.uuid);
        const alreadySelected = this.selected.findIndex((inF) => inF.uuid === rowData.uuid);
        if (outerIndex >= 0) {
            if (alreadySelected >= 0) {
                this.model[outerIndex]['selected'] = false;
                this.selected.splice(alreadySelected, 1);

                if (
                    this.selected.length === this.service.listSize ||
                    this.selected.length === MAX_PAGE_SIZE
                ) {
                    this.maxSelected = true;
                } else {
                    this.maxSelected = false;
                }
            } else {
                if (this.selected.length === MAX_PAGE_SIZE) {
                    this.messagesService.add({
                        severity: 'error',
                        summary: 'Errore',
                        detail: `Impossibile selezionare più di ${MAX_PAGE_SIZE} elementi.`,
                    });

                    this.model[outerIndex]['selected'] = false;
                } else {
                    const index = this.selected.findIndex((inF) => inF.uuid === rowData.uuid);
                    if (index < 0) {
                        this.selected.push(rowData);
                        this.model[outerIndex]['selected'] = true;
                    } else {
                        this.selected.splice(index, 1);
                        this.model[outerIndex]['selected'] = false;
                    }

                    if (
                        this.selected.length === this.service.listSize ||
                        this.selected.length === MAX_PAGE_SIZE
                    ) {
                        this.maxSelected = true;
                    } else {
                        this.maxSelected = false;
                    }
                }
            }
        }
    }
    onManualSelectAll(): void {
        if (!this.maxSelected) {
            this.selected = [];

            for (const item of this.model) {
                const find = this.selected.find((inF) => inF.uuid === item.uuid);

                if (find) {
                    item['selected'] = true;
                } else {
                    item['selected'] = false;
                }
            }
        } else {
            this.selectLoading = true;
            this.articleService.getMaxArticles().subscribe({
                next: (maxArticles) => {
                    this.selected = maxArticles;

                    for (const item of this.model) {
                        const find = this.selected.find((inF) => inF.uuid === item.uuid);

                        if (find) {
                            item['selected'] = true;
                        } else {
                            item['selected'] = false;
                        }
                    }

                    this.selectLoading = false;
                },
                error: (error) => {
                    this.selectLoading = false;
                    this.messagesService.add({
                        severity: 'error',
                        summary: 'Errore',
                        detail: error,
                    });
                },
            });
        }
    }
}
