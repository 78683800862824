import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { Container } from '../model/container';
import { CONTAINERS_API_PATH } from '../constants/constants';
import { tap, map } from 'rxjs/operators';

import { MessageService, SelectItem } from 'primeng/api';
import { ConfigurationService } from './configuration.service';
import { Printable } from '../model/printable';
import { catchError } from 'rxjs/operators';
import { Catalogue } from '../model/catalogue';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ContainerService extends AbstractService<Container> {
    printpath: string;
    containersTranscode: Map<string, string> = new Map<string, string>();

    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        private configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(CONTAINERS_API_PATH), httpClient, messageService);
        configurationService
            .getValue(CONTAINERS_API_PATH)
            .subscribe((result) => (this.printpath = result));
    }

    public getAllCodicigaraAsItems(): Observable<SelectItem[]> {
        return this.getAllList().pipe(
            map((gare) =>
                gare.map((gara) => ({
                    label: `${gara.codiceGara} - ${gara.description ?? ''}`,
                    value: gara.codiceGara,
                }))
            )
        );
    }

    getAllListAsItems(): Observable<SelectItem[]> {
        return this.getAllList().pipe(
            map((container) => container.map((t) => ({ label: t.name, value: t })))
        );
    }

    public getId(element: Container) {
        return element.codiceGara;
    }

    public containersAsItems(): Observable<SelectItem[]> {
        this.buildSearch();
        return this.getAllList().pipe(
            map((res) =>
                res.map((v) => ({
                    label: v.name,
                    value: v,
                }))
            )
        );
    }

    public containersUuidAsItems(): Observable<SelectItem[]> {
        return this.getAllList().pipe(
            map((res) =>
                res.map((v) => ({
                    label: v.name,
                    value: v.codiceGara,
                }))
            )
        );
    }

    public buildSearch() {
        this.search = new Search<Container>(Container);
    }

    public getPrintables(
        uuid: string,
        search: Search<Printable>
    ): Observable<{ printables: Printable[]; listSize: number }> {
        let params = new HttpParams();

        if (search == null) {
            search = JSON.parse(JSON.stringify(this.search));
        }

        params = this.applyRestrictions(params, search);

        return this.httpClient
            .get(`${this.printpath}/${uuid}/printables`, {
                observe: 'response',
                params,
            })
            .pipe(
                map((res) => ({
                    printables: res.body,
                    listSize: res.headers.get('listSize'),
                })),
                catchError(this.handleError)
            );
    }

    public addPrintable(uuid: string, printable: Printable) {
        return this.httpClient
            .post(`${this.printpath}/${uuid}/printables`, printable)
            .pipe(catchError(this.handleError));
    }

    public deletePrintable(uuid: string, printableUuid: string) {
        return this.httpClient
            .delete(`${this.printpath}/${uuid}/printables/${printableUuid}`)
            .pipe(catchError(this.handleError));
    }

    public getCatalogues(uuid: string): Observable<Catalogue[]> {
        return this.httpClient
            .get(`${this.printpath}/${uuid}/catalogues`)
            .pipe(catchError(this.handleError));
    }

    public addCatalogue(uuid: string, catalogue: Catalogue) {
        return this.httpClient
            .post(`${this.printpath}/${uuid}/catalogues`, catalogue)
            .pipe(catchError(this.handleError));
    }

    public cloneCatalogue(uuid: string, catalogueUuid: string): Observable<Catalogue> {
        return this.httpClient
            .post<Catalogue>(`${this.printpath}/${uuid}/catalogues/${catalogueUuid}/clone`, {})
            .pipe(catchError(this.handleError));
    }

    public deleteCatalogue(uuid: string, catalogueUuid: string) {
        return this.httpClient
            .delete(`${this.printpath}/${uuid}/catalogues/${catalogueUuid}`)
            .pipe(catchError(this.handleError));
    }

    public deleteAllCatalogues(uuid: string) {
        return this.httpClient
            .delete(`${this.printpath}/${uuid}/catalogues`)
            .pipe(catchError(this.handleError));
    }

    public archiveContainer(uuid: string) {
        return this.httpClient
            .post(`${this.printpath}/archive/${uuid}`, null)
            .pipe(catchError(this.handleError));
    }

    public unarchiveContainer(uuid: string) {
        return this.httpClient
            .post(`${this.printpath}/unarchive/${uuid}`, null)
            .pipe(catchError(this.handleError));
    }

    public closeContainer(uuid: string) {
        return this.httpClient
            .post(`${this.printpath}/close/${uuid}`, null)
            .pipe(catchError(this.handleError));
    }

    public openContainer(uuid: string) {
        return this.httpClient
            .post(`${this.printpath}/unclose/${uuid}`, null)
            .pipe(catchError(this.handleError));
    }

    customUpdateContainer(oldCode: string, container: Container): Observable<Container> {
        console.log(
            'this.configurationService.getValue(CONTAINERS_API_PATH)',
            this.configurationService.getValue(CONTAINERS_API_PATH)
        );

        const path = `${this.url}/${oldCode}`;
        return this.httpClient.put<Container>(path, container);
    }
}
