import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { LISTABLES_API_PATH } from '../constants/constants';
import { map } from 'rxjs/operators';

import { MessageService, SelectItem } from 'primeng/api';
import { ConfigurationService } from './configuration.service';
import { Listable } from '../model/listable';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ListableService extends AbstractService<Listable> {
    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(LISTABLES_API_PATH), httpClient, messageService);
    }

    public getId(element: Listable) {
        return element.uuid;
    }

    public buildSearch() {
        this.search = new Search<Listable>(Listable);
    }

    // fornisce la lista di liste (nomi)
    public listNames() {
        const params = new HttpParams();
        return this.httpClient.get<string[]>(this.url + '/distinct', {
            params,
        });
    }

    public listNamesAsItems(): Observable<SelectItem[]> {
        return this.listNames().pipe(
            map((res) =>
                res.map((v) => ({
                    label: v,
                    value: v,
                }))
            )
        );
    }

    // fornisce le singole voci per singola lista
    public listValues(name: string) {
        const params = new HttpParams();
        return this.httpClient.get<string[]>(this.url + '/distinct/' + name, {
            params,
        });
    }

    public listValuesAsItems(name: string): Observable<SelectItem[]> {
        return this.listValues(name).pipe(
            map((res) =>
                res.map((v) => ({
                    label: v,
                    value: v,
                }))
            )
        );
    }

    getListablesCategories(query: string): Observable<any> {
        let path = `${this.url}/distinct/categoria?startRow=0&pageSize=20`;
        if (query) {
            path += `&like.value=${query}`;
        }

        return this.httpClient.get(path);
    }
}
