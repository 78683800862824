import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { Printable } from '../model/printable';
import {
    CONTAINERS_API_PATH,
    LISTABLES_API_PATH,
    PRINTABLES_API_PATH,
    PRINT_PATH,
    PRINT_TEMPLATES_API_PATH,
} from '../constants/constants';
import { Observable } from 'rxjs';
import { first, map, flatMap } from 'rxjs/operators';

import { MessageService, SelectItem } from 'primeng/api';
import { ConfigurationService } from './configuration.service';
import { PrintablePropertyPatch } from '../model/printable-property-patch';
import { FormArray, FormGroup } from '@angular/forms';
import { Group } from '../model/group';

@Injectable({
    providedIn: 'root',
})
export class PrintableService extends AbstractService<Printable> {
    printpath: string;

    printTemplate: string = null;

    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        protected configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(PRINTABLES_API_PATH), httpClient, messageService);
        configurationService.getValue(PRINT_PATH).subscribe((result) => (this.printpath = result));
    }

    public getId(element: Printable) {
        return element.uuid;
    }

    patchUrl(uuid: string): void {
        this.configurationService.getValue(CONTAINERS_API_PATH).subscribe({
            next: (url) => {
                this.url = `${url}/${uuid}/printables`;
            },
        });
    }

    public clonePrintable(printable: Printable, codiceGara: string): Observable<Printable> {
        printable.uuid = null;
        printable.codiceGara = codiceGara;
        return this.persist(printable);
    }

    public printablesAsItems(): Observable<SelectItem[]> {
        return this.getAllList().pipe(
            map((res) =>
                res.map((v) => ({
                    label: v.name,
                    value: v,
                }))
            )
        );
    }

    public groups(uuid: string): Observable<Group[]> {
        return this.httpClient.get<Group[]>(this.printpath + '/catalogues/' + uuid + '/groups');
    }

    public buildSearch() {
        this.search = new Search<Printable>(Printable);
    }

    getPdf(uuid: string, uuidTemplate: string): Observable<any> {
        return this.httpClient.get(
            `${this.printpath}/printables/${uuid}/printemplate/${uuidTemplate}`,
            { responseType: 'blob' }
        );
    }

    getPdfWithAttachmetns(uuid: string, uuidTemplate: string): Observable<any> {
        return this.httpClient.get(
            this.printpath +
                '/printables/' +
                uuid +
                '/printemplate/' +
                uuidTemplate +
                '?withAttachements=true',
            { responseType: 'blob' }
        );
    }

    getPrintablesWithImages(): Observable<Printable[]> {
        const tmpObjectType = this.search.obj.objectType;
        this.search.obj.objectType = null;
        const obs = this.getAllList().pipe(
            map((printables) => {
                printables = printables.filter((printable) => {
                    printable.properties = printable.properties.filter(
                        (p) => p.property_type === 'IMAGE_VALUE' && p.value !== ''
                    );
                    return printable.properties.length > 0;
                });
                return printables;
            })
        );
        this.search.obj.objectType = tmpObjectType;
        return obs;
    }

    patchPropertiesValue(uuid: string, printablePropertyPatch: PrintablePropertyPatch) {
        const body = printablePropertyPatch;
        return this.httpClient.put(`${this.url}/${uuid}`, body);
        // return this.httpClient.patch(`${this.url}/${uuid}`, body);
    }

    applyPropertyFilters(form: FormGroup) {
        const { propertiesToFilter } = form.getRawValue();
        for (const propertyControl of propertiesToFilter) {
            const { name, jsonTypes, value1, value2, property } = propertyControl;
            if (jsonTypes?.length > 0 && property && name && value1 !== null) {
                this.search.json[`${name}_type`] = property.property_type;
                for (const jsonType of jsonTypes) {
                    switch (jsonType) {
                        case 'obj':
                            this.search.json[`${name}_${jsonType}`] = value1;
                            break;
                        case 'like':
                            this.search.json[`${name}_${jsonType}`] = value1;
                            break;
                        case 'gte':
                            if (property.property_type === 'DATE_VALUE') {
                                this.search.json[`${name}_${jsonType}`] =
                                    (value1 as Date)?.toISOString() ?? null;
                            } else {
                                this.search.json[`${name}_${jsonType}`] = value1 ?? null;
                            }
                            break;
                        case 'lte':
                            if (property.property_type === 'DATE_VALUE') {
                                this.search.json[`${name}_${jsonType}`] =
                                    (value2 as Date)?.toISOString() ?? null;
                            } else {
                                this.search.json[`${name}_${jsonType}`] = value2 ?? null;
                            }
                            break;
                    }
                }
            }
        }
    }

    public removeEmptyPropertyFilters(form: FormGroup) {
        const { propertiesToFilter }: { propertiesToFilter: FormArray } = form.getRawValue();
        let indexesToRemove: number[] = [];
        for (let i = 0; i < propertiesToFilter.length; i++) {
            if (!propertiesToFilter[i].jsonTypes || propertiesToFilter[i].jsonTypes.length === 0) {
                indexesToRemove = [i, ...indexesToRemove];
            }
        }
        for (const idx of indexesToRemove) {
            (form.get('propertiesToFilter') as FormArray).removeAt(idx);
        }
    }

    checkAndRemoveZombieJsonTypes() {
        let jsonTypes: string[] = [];
        let jsonValues: string[] = [];
        for (const property in this.search.json) {
            if (property.endsWith('_type')) {
                jsonTypes = [...jsonTypes, property.replace('_type', '')];
            } else {
                const unWrappedProp = property.replace(/\_.+/, '');
                const alreadyIn = jsonValues.find((v) => v === unWrappedProp);
                if (!alreadyIn) {
                    jsonValues = [...jsonValues, unWrappedProp];
                }
            }
        }

        for (const type of jsonTypes) {
            if (!jsonValues.find((v) => v === type)) {
                this.search.json[`${type}_type`] = null;
            }
        }
    }

    saveFilters() {
        const filtersJSON = JSON.stringify(this.search);
        localStorage.setItem('articles_search', filtersJSON);
    }

    public loadSavedFilters() {
        const item = localStorage.getItem(`articles_search`);
        if (item) {
            const search = JSON.parse(item);
            this.search = { ...search };
            localStorage.removeItem(`articles_search`);
        }
    }

    getTemplate() {
        if (!this.printTemplate) {
            this.configurationService
                .getValue(PRINT_TEMPLATES_API_PATH)
                .pipe(
                    flatMap((url) =>
                        this.httpClient
                            .get(`${url}?obj.printTemplateType=PRINTABLE&obj.defaultTemplate=true`)
                            .pipe(first())
                    )
                )
                .subscribe(
                    (templateArr: any[]) => (this.printTemplate = templateArr[0].uuid || null)
                );
        }
    }

    download(payload: any) {
        return this.httpClient.post(`${this.url}/export`, payload, {
            responseType: 'blob',
        });
    }

    upload(payload: any) {
        return this.httpClient.post(`${this.url}/upload`, payload);
    }

    downloadTemplate(payload: any) {
        return this.httpClient.post(`${this.url}/export/template`, payload, {
            responseType: 'blob',
        });
    }

    verify(payload: any) {
        return this.httpClient.post(`${this.url}/verify`, payload);
    }
}
